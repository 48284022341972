/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, Dispatch } from 'react';
import { useQuery } from 'react-query';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';
import CloudOutlinedIcon from '@mui/icons-material/CloudOutlined';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import { SideLink } from '../components/SideNav';
import { AuthUserApiClient, User } from '../api';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const defaultUseState: Dispatch<any> = () => {};

const links: { guest: SideLink[]; developer: SideLink[]; administrator: SideLink[] } = {
  guest: [
    { text: 'Home', path: '/', icon: <HomeOutlinedIcon /> },
    { text: 'Database', path: '/requests', icon: <StorageOutlinedIcon /> },
    { text: 'AWS', path: '/aws', icon: <CloudOutlinedIcon /> },
    { text: 'Tribes', path: '/tribes', icon: <Groups2OutlinedIcon /> },
  ],
  developer: [],
  administrator: [],
};

const getNavLinks = (role: User.RoleEnum): SideLink[] => {
  switch (role) {
    case User.RoleEnum.Guest:
      return links.guest;
    case User.RoleEnum.User:
      return [...links.developer, ...links.guest];
    case User.RoleEnum.Administrator:
      return [...links.administrator, ...links.developer, ...links.guest];
    default:
      return links.guest;
  }
};

export type Permissions = {
  canAllowAccessDatabase?: boolean;
  canRequestReadProductionDatabase?: boolean;
  canRequestWriteProductionDatabase?: boolean;
  canUserAddDeveloperToATribe?: boolean;
  canUserAddOwnerToATribe?: boolean;
  canUserCreateTribe?: boolean;
};

const mockAuthenticatedUser: User = {
  id: 1,
  email: 'james.higgins@eposnow.com',
  role: User.RoleEnum.Guest,
};

const emptyPermissions: Permissions = {};

const UserContext = React.createContext({
  authenticatedUser: { ...mockAuthenticatedUser },
  userPermissions: emptyPermissions,
  setAuthenticatedUser: defaultUseState,
  fetchData: () => {},
  isError: false,
  isLoading: true,
  navItems: getNavLinks(User.RoleEnum.Guest),
});

const UserContextProvider = ({ children }: { children: JSX.Element }) => {
  // @TODO - when we have authenticated routes, this should be dropped and use the deconstruct from useQuery object. like: { data: authenticatedUser } = useQuery

  const [authenticatedUser, setAuthenticatedUser] = useState(mockAuthenticatedUser);
  const [showSuccessEditUser] = useState(false);
  const [errorMessageEditUser] = useState<string>('');
  const [userPermissions, setPermissions] = useState<Permissions>(emptyPermissions);
  const [navItems, setNavItems] = useState<SideLink[]>(getNavLinks(User.RoleEnum.Guest));

  const {
    refetch: fetchData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: 'user',
    queryFn: () => AuthUserApiClient.userGetMe(),
    onSuccess: (res) => {
      setAuthenticatedUser(res.data.user);
      setNavItems(getNavLinks(res.data.user.role));
      setPermissions(res.data.permissions);
    },
    onError: () => {
      setAuthenticatedUser({
        ...authenticatedUser,
      });
    },
  });

  const contextValue = {
    authenticatedUser,
    setAuthenticatedUser,
    userPermissions,
    fetchData,
    isLoading,
    isError,
    showSuccessEditUser,
    errorMessageEditUser,
    navItems,
  };

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

export { UserContext, UserContextProvider };
/* eslint-enable @typescript-eslint/no-empty-function */
